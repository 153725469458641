export const convertCustomSet = (oriCustomSet) => {
    const colorCode = typeof oriCustomSet.colorCode === 'string' ? JSON.parse(oriCustomSet.colorCode) : oriCustomSet.colorCode;
    const example = typeof oriCustomSet.examples === 'string' ? JSON.parse(oriCustomSet.examples) : oriCustomSet.examples;
    return {
        logoInput: oriCustomSet.profileImg,
        nameInput: oriCustomSet.name,
        colorInput: {
            color0: `rgba(${colorCode[0].rgb.r}, ${colorCode[0].rgb.g}, ${colorCode[0].rgb.b}, ${colorCode[0].rgb.a})`,
            color1: `rgba(${colorCode[1].rgb.r}, ${colorCode[1].rgb.g}, ${colorCode[1].rgb.b}, ${colorCode[1].rgb.a})`,
            color2: `rgba(${colorCode[2].rgb.r}, ${colorCode[2].rgb.g}, ${colorCode[2].rgb.b}, ${colorCode[2].rgb.a})`, 
        },
        greetingInput: oriCustomSet.greetingMessage,
        recommendSizeInput: oriCustomSet.recommendSize,
        carouselDirInput: oriCustomSet.imageRatio,
        exceptKeyword: oriCustomSet.exceptKeyword,
        example: example
    }
}