import { useState, useEffect, useRef } from 'react';
import compStyle from '../../style/styles/Components.module.css';
import style from '../../style/styles/Chat.module.css';
import styleMsg from '../../style/styles/Message.module.css';
import sendIcon from '../../images/send_icon.svg';
import sendIconActive from '../../images/send_icon_active.svg';
import CircularProgress from '@mui/material/CircularProgress';

import Message from './MessageTextBase';
import { fetchChatbotData, getChatbotReplyAnchovy, resetQueryConditionAnchovy } from '../../utils/apis';
import { CarouselFAB, ShortcutBanner } from '../CarouselFAB';
import { Header, HeaderMobile } from '../Header/Header';
import { initSettings } from '../../data/initSetting';
import { ProgressDLST } from '../Chat/Skeleton';
import { convertCustomSet } from '../ParseCustomSet';
import { gtagSendMessage } from '../../utils/gtag';
import ExampleGroup from '../Chat/Example';

const ChatFieldBase = (props) => {
    // let { customProperty, reqParams } = props;
    // const chatbotURL = customProperty.apiUrls.chatbotURL;
    // const chatURL = customProperty.apiUrls.chatURL;
    // const exampleURL = customProperty.apiUrls.exampleURL;
    // const resetURL = customProperty.apiUrls.resetURL;
    // const apiKey = customProperty.apiKey;
    // const chatUserId = reqParams.chatUserId;
    // const partnerId = reqParams.partnerId;
    // const partnerType = reqParams.partnerType;

    // //COMM: Change page title
    // document.title = '나만의 AI 쇼핑 메이트 젠투';

    // let [messages, setMessages] = useState([]);
    // let [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
    // let [input, setInput] = useState('');
    // let [isMobileDevice, setIsMobileDevice] = useState(false);
    // let [isMobileView, setIsMobileView] = useState(reqParams.isMobile || window.innerWidth < 601);
    // let [resetBtn, setResetBtn] = useState(false);
    // let [isInputFocused, setIsInputFocused] = useState(false);
    // let [isBtnVisible, setIsBtnVisible] = useState(false);
    // let [productIdx, setProductIdx] = useState(0);
    // let [isBSActive, setIsBSActive] = useState(false);
    // let [isChatStarted, setIsChatStarted] = useState(false);
    // let [isMoreEnabled, setIsMoreEnabled] = useState(false);
    // const [customSet, setCustomSet] = useState(initSettings);
    // const getChatbotReplyFunc = getChatbotReplyAnchovy;

    // document.documentElement.style.setProperty('--color-prim-800', customSet.colorInput.color0);
    // document.documentElement.style.setProperty('--color-prim-200', customSet.colorInput.color1);
    // document.documentElement.style.setProperty('--color-prim-100', customSet.colorInput.color2);

    // // const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    // const messagesEndRef = useRef(null);
    // const carouselEndRef = useRef(null);
    // const inputRef = useRef(null);

    // const onClick = async () => {
    //     if (isGeneratingAnswer) return;
    //     if (input === '') return;
    //     if (isBSActive) return;
    //     const itemId = sessionStorage.getItem('itemId');
    //     const userCount = messages.filter(item => item.user !== 'Accio').length;
    //     let message = [{id: userCount, user: chatUserId, message: input, time: new Date().toString() }];
    //     setMessages((prev) => prev.concat(message));
    //     setInput('');
    //     const chatbotReply = await getChatbotReplyFunc(
    //         input, 
    //         chatUserId, 
    //         setIsGeneratingAnswer, 
    //         chatURL, 
    //         partnerType, 
    //         apiKey, 
    //         itemId, 
    //         reqParams.isMobile, 
    //         partnerId
    //     );
    //     setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: chatbotReply.text, example: chatbotReply?.example || ['비슷한 상품 더 추천해줘', '이 상품의 특장점은 뭐야?'], product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent, userGroup: chatbotReply?.userGroup}));

    //     gtagSendMessage(userCount, "Manual Input", input, partnerId)
    // };

    // const handleKeyPress = async (e) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         if (isGeneratingAnswer) return;
    //         onClick();
    //     }
    // };

    // const onChange = (e) => {
    //     e.preventDefault();
    //     if (isGeneratingAnswer) return;
    //     if (isBSActive) return;
    //     setInput(e.currentTarget.value);
    // };

    // //COMM: Auto scroll to bottom when new message is present
    // const scrollToBottom = () => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    // };

    // const handleButtonClick_send = async (e, text, itemId = 'general') => {
    //     e.stopPropagation();
    //     if (isGeneratingAnswer) return;
    //     const userCount = messages.filter(item => item.user !== 'Accio').length;
    //     let message = [{id: userCount,  user: chatUserId, message: text }];
    //     setMessages((prev) => prev.concat(message));
    //     setInput('');
    //     const chatbotReply = await getChatbotReplyFunc(
    //         text, 
    //         chatUserId, 
    //         setIsGeneratingAnswer, 
    //         chatURL, 
    //         partnerType, 
    //         apiKey, 
    //         itemId, 
    //         reqParams.isMobile, 
    //         partnerId
    //     );
    //     setMessages((prev) => prev.concat({id: userCount+1, user: 'Accio', message: chatbotReply.text,  example: ['비슷한 상품 더 추천해줘', '이 상품의 특장점은 뭐야?'], product: chatbotReply?.product, dialId: chatbotReply?.dialogueId, intent: chatbotReply?.intent }));

    //     gtagSendMessage(userCount, "Example Message Click", text, partnerType);
    // };

    // const sendInputFocusStatus = (state) => {
    //     const targetWindow = window.parent;
    //     const inputFocusState = {
    //         inputFocusState: state,
    //     }
    //     targetWindow.postMessage(inputFocusState, "*");
    // }

    // const handleInputFocus = (e) => {
    //     setIsInputFocused(true);
    //     sendInputFocusStatus(true);
    //     if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendInputFocusState) {
    //         window.webkit.messageHandlers.sendInputFocusState.postMessage(`input focused`);
    //     } else {
    //         console.warn('webkit.messageHandlers is not available');
    //     }

    //      // AOS SDK로 메시지 전달
    //     if (window.Android && window.Android.sendInputFocusState) {
    //         window.Android.sendInputFocusState('input focused');
    //     } else {
    //         console.warn('Android interface is not available');
    //     }

    //     if (!isChatStarted) {
    //         setIsChatStarted(true);
    //     }
    // }

    // const handleInputBlur = () => {
    //     setIsInputFocused(false);
    //     sendInputFocusStatus(false);
    // }

    // const handleScroll = () => {
    //     if (carouselEndRef.current) {
    //         const rect = carouselEndRef.current.getBoundingClientRect();
    //         setIsBtnVisible(rect.top < (isMobileDevice ? 16 : 100));
    //     }
    // };

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         document.getElementById('ChatField')?.addEventListener('scroll', handleScroll());
    //     }, 100);
    //     return () => {
    //         clearInterval(timer);
    //         document.getElementById('ChatField')?.removeEventListener('scroll', handleScroll());
    //     }
    // })

    // useEffect(() => {
    //     // scroll to bottom when new msg sent
    //     if (messages.length === 1) {
    //         document.getElementById('ChatField').scrollTo(0, 0);
    //     } else {
    //         scrollToBottom();
    //     }
    // }, [messages, resetBtn]);

    // useEffect(() => {
    //     //COMM: Check whether the device is mobile or not
    //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //     setIsMobileDevice(isMobile);

    //     //COMM: Move VoiceOver focus to HEADER
    //     setTimeout(() => {
    //         const header = document.getElementById('HEADER');
    //         if (header) {
    //             header.tabIndex = -1;
    //             header.focus();
    //         }
    //     }, 250);
    // }, []);

    // useEffect(() => {
    //     fetchChatbotData(chatbotURL, partnerId)
    //         .then(res => convertCustomSet(res))
    //         .then(res => {setCustomSet(res)})
    // },[])

    // // API request the reset of chat history at server
    // useEffect(() => {
    //     resetQueryConditionAnchovy(chatUserId, resetURL, partnerId, partnerType, apiKey);
    // }, [chatUserId]);

    // useEffect(() => {
    //     if (messages[messages.length - 1]?.user === 'Accio' && !messages[messages.length - 1].product) {
    //         setResetBtn(true)
    //     } else {
    //         setResetBtn(false)
    //     }
    // }, [messages]);

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: 'gray', position: 'absolute', top: 0, left: 0 }}>
            <div style={{ width: '100%', height: '182px', display: 'flex', flexDirection: 'column', width: '100%', height: 'fit-content', position: 'relative' }}>
                <div>
                    <div style={{ width: '100%', height: '42px', backgroundColor: 'white', border: '1px solid black' }}></div>
                    <div style={{ width: '100%', height: '42px', backgroundColor: 'white', border: '1px solid black' }}></div>
                    <div style={{ width: '100%', height: '42px', backgroundColor: 'white', border: '1px solid black' }}></div>
                </div>
                <div style={{
                    width: '100%',
                    height: '40px', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    backgroundColor: 'green', 
                    padding: '10px 16px'
                }}>
                    <div style={{ width: '20px', height: '20px' }}></div>
                    <div>...</div>
                    <img src='/img/display/display-close.png' width={20} height={20} />
                </div>
            </div>
            <div style={{ width: '100%', backgroundColor: 'blue', flex: 1, position: 'relative' }}>
                <div style={{ width: '100%', height: '42px', backgroundColor: 'white', border: '1px solid black' }}></div>
                <div style={{ width: '100%', height: '42px', backgroundColor: 'white', border: '1px solid black' }}></div>
                <div style={{ width: '100%', height: '42px', backgroundColor: 'white', border: '1px solid black' }}></div>
            </div>
        </div>
    );
};

export default ChatFieldBase