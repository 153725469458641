import { addComma, getCustomProperty, getServiceType } from "../../utils/utils";
import { CarouselDlstHori } from "../Custom/Carousel";
import rsStyle from '../../style/styles/RecommendSection.module.css';
import compStyle from '../../style/styles/Components.module.css';
import { useEffect, useState } from "react";
import { fetchRecommendProducts } from "../../utils/apis";

const RecommendSection = () => {
    // const product = [
    //     {
    //         "type": "추천 상품",
    //         "serviceType": "스토어",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/a8194bdd-d326-44aa-9b6a-eab80aa25c81.webp",
    //         "itemId": "2364",
    //         "name": "토마시 아마로네 델라 발폴리첼라 클라시코",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__2364__dlstweb",
    //         "originPrice": 61000,
    //         "price": 61000,
    //         "discount": 0,
    //         "rate": 4.9,
    //         "reviewNum": 40,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.3",
    //         "summary": null
    //     },
    //     {
    //         "type": "관련 상품",
    //         "serviceType": "파트너",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/17f9b59a-c6ff-4d49-8347-1465d692d70a.webp",
    //         "itemId": "1902",
    //         "name": "1865 마스터 블렌드 골프백 패키지",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__1902__dlstweb",
    //         "originPrice": 68600,
    //         "price": 57000,
    //         "discount": 17,
    //         "rate": 4.9,
    //         "reviewNum": 43,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.1",
    //         "summary": null
    //     },
    //     {
    //         "type": "관련 상품",
    //         "serviceType": "파트너",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/2ccdb799-cbd0-4fbf-816c-78366b6e6290.webp",
    //         "itemId": "2988",
    //         "name": "셀리에 데 프린스 샤또네프 뒤 파프",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__2988__dlstweb",
    //         "originPrice": 74500,
    //         "price": 52900,
    //         "discount": 29,
    //         "rate": 4.0,
    //         "reviewNum": 1,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.0",
    //         "summary": null
    //     },
    //     {
    //         "type": "관련 상품",
    //         "serviceType": "파트너",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/2ccdb799-cbd0-4fbf-816c-78366b6e6290.webp",
    //         "itemId": "2988",
    //         "name": "셀리에 데 프린스 샤또네프 뒤 파프",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__2988__dlstweb",
    //         "originPrice": 74500,
    //         "price": 52900,
    //         "discount": 29,
    //         "rate": 4.0,
    //         "reviewNum": 1,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.0",
    //         "summary": null
    //     },
    //     {
    //         "type": "관련 상품",
    //         "serviceType": "파트너",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/2ccdb799-cbd0-4fbf-816c-78366b6e6290.webp",
    //         "itemId": "2988",
    //         "name": "셀리에 데 프린스 샤또네프 뒤 파프",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__2988__dlstweb",
    //         "originPrice": 74500,
    //         "price": 52900,
    //         "discount": 29,
    //         "rate": 4.0,
    //         "reviewNum": 1,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.0",
    //         "summary": null
    //     },
    //     {
    //         "type": "관련 상품",
    //         "serviceType": "파트너",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/2ccdb799-cbd0-4fbf-816c-78366b6e6290.webp",
    //         "itemId": "2988",
    //         "name": "셀리에 데 프린스 샤또네프 뒤 파프",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__2988__dlstweb",
    //         "originPrice": 74500,
    //         "price": 52900,
    //         "discount": 29,
    //         "rate": 4.0,
    //         "reviewNum": 1,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.0",
    //         "summary": null
    //     },
    //     {
    //         "type": "관련 상품",
    //         "serviceType": "파트너",
    //         "imageUrl": "https://dailyshotassets-test.s3.ap-northeast-2.amazonaws.com/dailyshotassets-test/media/smartorder_reservation/product/thumbnail/2ccdb799-cbd0-4fbf-816c-78366b6e6290.webp",
    //         "itemId": "2988",
    //         "name": "셀리에 데 프린스 샤또네프 뒤 파프",
    //         "productUrl": "https://pmlaw3yveqh6facwfocet43r540smhrz.lambda-url.ap-northeast-2.on.aws/?v=565179963_20241115163715__2988__dlstweb",
    //         "originPrice": 74500,
    //         "price": 52900,
    //         "discount": 29,
    //         "rate": 4.0,
    //         "reviewNum": 1,
    //         "shortDesc": "",
    //         "tags": [],
    //         "vivino_rate": "4.0",
    //         "summary": null
    //     }
    // ]
    const [product, setProduct] = useState([]);

    useEffect(() => {
        const recommendationUrl = getCustomProperty('anchovy').apiUrls.chatbotURL;
        fetchRecommendProducts(recommendationUrl, 1)
            .then(res => setProduct(res))
    }, [])

    return (
        <div className={rsStyle.RecommendSection__Container}>
            <div className={rsStyle.RecommendSection__TopWrap}>
                <div className={rsStyle.RecommendSection__TopWrap__buffer} />
                <div style={{display: 'flex'}}>
                    <img 
                        src="/img/units/section-ai-logo.png"
                        className={rsStyle.RecommendSection__TopWrap__img}
                    />
                    <div className={compStyle.Spacing__8} />
                    <p className={rsStyle.RecommendSection__TopWrap__title}>
                        맞춤 추천 상품
                    </p>
                </div>
                <img src={"/img/units/section-logo.png"} width={"79px"} height={"18px"} />
            </div>
            <div className={rsStyle.RecommendSection__CarouselContainer}>
                <div className={rsStyle.RecommendSection__CarouselWrap}>
                    {
                        product?.map((data, idx) => {
                            return (
                                <div key={idx} className={rsStyle.RecommendSection__CarouselWrap__card}>
                                    <div style={{width: '105px', height: '140px', backgroundImage: `url(${data.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                        {
                                            data.vivino_rate?.length > 0 &&
                                            <div style={{position: 'relative', top: '105px', width: 'fit-content', height: 'fit-content', background: '#fff', borderRadius: '10px', padding: '4px', display: 'flex', flexDirection: 'column'}}>
                                                <img src='/img/units/carousel-vivino.png' width={25} height={5} />
                                                <p className='vivino-font'>{data.vivino_rate}</p>
                                            </div>
                                        }
                                    </div>
                                    <div className={compStyle.Spacing__8} />
                                    <div>
                                        <div style={{minHeight: '16px'}}>
                                            {
                                                data.serviceType &&
                                                <img src={getServiceType(data.serviceType)} width={"50px"} height={"16px"}/>
                                            }
                                        </div>
                                        <div className={compStyle.Spacing__4} />
                                        <p className='h6'
                                            style={{
                                                maxWidth: '141px',
                                                minHeight: '33px',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {data.name}
                                        </p>
                                        <div className={compStyle.Spacing__8} />
                                        <div style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                                            {
                                                data.discountRate !== 0 ?
                                                <div>
                                                    <p
                                                        className='h9'
                                                        style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                                    >
                                                        {addComma(data.originPrice)}
                                                    </p>
                                                    <div
                                                        style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}
                                                    >
                                                        <p className='h1' style={{ color: "#ec3e48" }}> {data.discountRate}%</p>
                                                        <div className={compStyle.Spacing__4} />
                                                        <p className='h1'>{addComma(data.price)}원</p>
                                                    </div>
                                                </div> :
                                                <div>
                                                    <div style={{ minHeight: '14px' }} />
                                                    <p className='h1'>
                                                        {addComma(data.price)}원
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                        <div className={compStyle.Spacing-4} />
                                        <div
                                            style={{ width: '100%', display: 'flex', alignItems: 'center', height: '15px' }}
                                        >
                                            <span style={{ height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                <img src='/img/units/carousel-star-gray.png' width={14} />
                                            </span>
                                            <p className='h9' style={{ width: 'fit-content', color: '#999999', wordBreak: 'keep-all' }}>
                                                {data.reviewRate.toFixed(1) < 0.1 ? '신규' : `${data.reviewRate.toFixed(1)} 리뷰 ${data.reviewCount}개`}
                                            </p>
                                        </div>
                                        {
                                            data.shortDesc &&
                                            <>
                                                <div className={compStyle.Spacing__8} />
                                                <div style={{width: '100%', overflow: 'hidden'}}>
                                                    <div style={{width: '100%', minHeight: '36px', color: '#222', fontWeight: 600, fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-word', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
                                                        <p
                                                            className='h8'
                                                            style={{
                                                                color: '#c0c0c0',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 1,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.shortDesc}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className={compStyle.Spacing-4} />
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {
                                            data.tags?.map((tag, idx) => {
                                                const textColor = tag.text_color;
                                                const backgroundColor = tag.background_color;
                                                const borderColor = tag.border_color;
                                                return (
                                                    <span 
                                                        className='badge-font'
                                                        style={{
                                                            height: '18px',
                                                            padding: '3px 4px',
                                                            border: `1px solid ${borderColor}`,
                                                            borderRadius: '3px',
                                                            background: `${backgroundColor}`,
                                                            color: `${textColor}`,
                                                            marginRight: '4px',
                                                        }}
                                                    >{tag.name}</span>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div> 
            <div className={compStyle.Spacing__40} />
        </div>
    )
}

export default RecommendSection;