import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchChatbotData } from "../../utils/apis";
import { getCustomProperty } from "../../utils/utils";

const Transaction = () => {
    const navigate = useNavigate();
    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const partnerType = useParams().partnerType;
    const partnerId = searchParams.get('ptid');
    const chatUserId = searchParams.get('cuid') || parseInt(Math.random()*1e9);
    let chatBotId;
    const chatbotURL = getCustomProperty('anchovy').apiUrls.chatbotURL;

    sessionStorage.setItem('t', searchParams.get('t'));
    sessionStorage.setItem('ch', searchParams.get('ch'));
    sessionStorage.setItem('fc', searchParams.get('fc'));
    sessionStorage.setItem('ptid', partnerId);
    sessionStorage.setItem('cuid', chatUserId);

    useEffect(() => {
        fetchChatbotData(chatbotURL, partnerId)
            .then(res => {
                chatBotId = res.chatbotId;
                let chatUrl;
                if (partnerType === 'dlst') {
                    chatUrl = `/dlst/${chatUserId}`; 
                } else {
                    chatUrl = `/chat/${partnerType}/${partnerId}/${chatBotId}/${chatUserId}`;
                    // cafe24 frontSDK를 이용, 접속한 쇼핑몰 회원 정보 호출 = chatUserId
            }
            navigate(chatUrl);
        })
    }, [])
}

export default Transaction;